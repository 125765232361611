@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
@font-face {
    font-family: "KoulenRegular";
    src: local("Koulen-Regular"),
     url(/static/media/Koulen-Regular.e23002c6.ttf) format("truetype");
    font-weight: bold;
    }
    
    .KoulenRegularFont {
        font-family: "KoulenRegular";
        font-size: 60px;;
       }

       @font-face {
        font-family: "NotoSans-Black";
        src: local("NotoSans-Black"),
         url(/static/media/NotoSans-Black.4a2324b5.ttf) format("truetype");
        font-weight: bold;
        }
        
        .NotoSans-BlackFont {
            font-family: "NotoSans-Black";
            font-size: 60px;;
           }       
       
a {
    color: rgb(248, 214, 10);
    text-decoration: none;
    font-size: 100px;
}

.Nav{
    width: 100vw;
    background-color: var(--background-primary);

}

.Navbar{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    width: 85%;
    height: 2rem;
    margin: 0 auto;
}

.logo{
    font-size: 2rem;
    transition: var(--transition);
    text-decoration: none;
}

.logo:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.Menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.Menu-item{
    margin: 0 1rem;
    color: var(--text-primary);
    cursor: pointer;
}

.Menu-item::after{
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: var(--text-primary);
    transition: width 0.3s ease;
}

.Menu-item:hover::after{
    width: 100%;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(/static/media/font.617a0a92.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "NotoSans-Black",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

