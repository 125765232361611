@font-face {
    font-family: "KoulenRegular";
    src: local("Koulen-Regular"),
     url("Koulen-Regular.ttf") format("truetype");
    font-weight: bold;
    }
    
    .KoulenRegularFont {
        font-family: "KoulenRegular";
        font-size: 60px;;
       }

       @font-face {
        font-family: "NotoSans-Black";
        src: local("NotoSans-Black"),
         url("NotoSans-Black.ttf") format("truetype");
        font-weight: bold;
        }
        
        .NotoSans-BlackFont {
            font-family: "NotoSans-Black";
            font-size: 60px;;
           }       
       