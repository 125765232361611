@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

a {
    color: rgb(248, 214, 10);
    text-decoration: none;
    font-size: 100px;
}

.Nav{
    width: 100vw;
    background-color: var(--background-primary);

}

.Navbar{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    width: 85%;
    height: 2rem;
    margin: 0 auto;
}

.logo{
    font-size: 2rem;
    transition: var(--transition);
    text-decoration: none;
}

.logo:hover{
    transform: scale(1.1);
}

.Menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.Menu-item{
    margin: 0 1rem;
    color: var(--text-primary);
    cursor: pointer;
}

.Menu-item::after{
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: var(--text-primary);
    transition: width 0.3s ease;
}

.Menu-item:hover::after{
    width: 100%;
}